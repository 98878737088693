/**
 * SentimentChart Component
 * 
 * Componente React que visualiza datos de análisis de sentimiento
 * en un gráfico circular con opciones de exportación a PDF.
 * 
 * @module SentimentChart
 * @author WEBCORE+
 * @version 1.0.0
 */

import React, { useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from "recharts";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

/**
 * Componente para visualizar los datos de análisis de sentimiento
 * 
 * @param {Object} props - Propiedades del componente
 * @param {Object} props.stats - Estadísticas de sentimiento (Positivo, Neutral, Negativo)
 * @returns {JSX.Element} Componente de gráfico de sentimiento
 */
function SentimentChart({ stats }) {
  const chartRef = useRef(null);
  
  // Configuración de colores para el gráfico
  const COLOR_CONFIG = {
    POSITIVE: "#198754", // Verde
    NEUTRAL: "#ffc107",  // Amarillo
    NEGATIVE: "#dc3545", // Rojo
  };
  
  // Preparar los datos para el gráfico, filtrando valores cero
  const data = useMemo(() => [
    { name: "Positivo", value: stats.Positivo || 0, color: COLOR_CONFIG.POSITIVE },
    { name: "Neutral", value: stats.Neutral || 0, color: COLOR_CONFIG.NEUTRAL },  
    { name: "Negativo", value: stats.Negativo || 0, color: COLOR_CONFIG.NEGATIVE }  
  ].filter(item => item.value > 0), [stats]);
  
  const COLORS = useMemo(() => data.map(item => item.color), [data]);
  
  /**
   * Componente personalizado para el tooltip
   * @param {Object} props - Propiedades del tooltip
   * @returns {JSX.Element|null} Tooltip personalizado
   */
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{ 
          backgroundColor: '#fff',
          padding: '5px 10px',
          border: '1px solid #ccc',
          borderRadius: '5px'
        }}>
          <p className="label" style={{ margin: 0 }}>{`${payload[0].name}: ${payload[0].value}%`}</p>
        </div>
      );
    }
    return null;
  };
  
  /**
   * Renderiza las etiquetas personalizadas dentro del gráfico
   * @param {Object} props - Propiedades para la etiqueta
   * @returns {JSX.Element} Etiqueta personalizada
   */
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  /**
   * Exporta el gráfico actual a un archivo PDF
   * @returns {void}
   */
  const exportToPDF = async () => {
    if (!chartRef.current) {
      console.error("No se pudo acceder al elemento del gráfico");
      return;
    }
    
    try {
      const canvas = await html2canvas(chartRef.current, { scale: 2 });
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: 'a4'
      });
      
      // Configuración del documento PDF
      const currentDate = new Date().toLocaleDateString();
      
      // Título y fecha
      pdf.setFontSize(18);
      pdf.text('Análisis de Sentimiento', pdf.internal.pageSize.getWidth() / 2, 20, { align: 'center' });
      
      pdf.setFontSize(10);
      pdf.text(`Generado el: ${currentDate}`, pdf.internal.pageSize.getWidth() / 2, 30, { align: 'center' });
      
      // Agregar imagen del gráfico
      const imgWidth = 180;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      
      pdf.addImage(
        imgData, 
        'PNG', 
        (pdf.internal.pageSize.getWidth() - imgWidth) / 2,
        40, 
        imgWidth, 
        imgHeight
      );
      
      // Agregar detalles de los datos
      pdf.setFontSize(12);
      let startY = 40 + imgHeight + 20;
      
      pdf.text('Detalle de Resultados:', 20, startY);
      startY += 10;
      
      data.forEach((item) => {
        pdf.setTextColor(item.color);
        pdf.text(`${item.name}: ${item.value}%`, 30, startY);
        startY += 8;
      });
      
      pdf.save('analisis-sentimiento.pdf');
    } catch (error) {
      console.error("Error al exportar a PDF:", error);
    }
  };

  return (
    <div className="sentiment-chart-container">
      <div className="sentiment-chart" ref={chartRef} style={{ width: '100%', height: '250px' }}>
        {data.length > 0 ? (
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
              <Legend verticalAlign="bottom" height={36} />
            </PieChart>
          </ResponsiveContainer>
        ) : (
          <div className="text-center py-4">No hay datos de sentimiento disponibles</div>
        )}
      </div>
      
      <div className="text-center mt-3">
        <button 
          className="btn btn-primary" 
          onClick={exportToPDF}
          disabled={data.length === 0}
          aria-label="Exportar a PDF"
        >
          <i className="fas fa-file-pdf me-2" aria-hidden="true"></i>
          Exportar a PDF
        </button>
      </div>
    </div>
  );
}

// Validación de propiedades
SentimentChart.propTypes = {
  stats: PropTypes.shape({
    Positivo: PropTypes.number,
    Neutral: PropTypes.number,
    Negativo: PropTypes.number
  }).isRequired
};

// Valores por defecto
SentimentChart.defaultProps = {
  stats: {
    Positivo: 0,
    Neutral: 0,
    Negativo: 0
  }
};

export default SentimentChart;